<style scoped></style>

<template>
  <div style="display: flex; flex-direction: column;background-color: #FFF;">
    <fm-title title-text="工资发布" @clickTitleMenu="clickTitleMenu" :title-menus="titleMenus" />
    <i-input style="width: 300px; margin: 0 10px;" placeholder="请输入名字或代码搜索" search @on-search="key => searchKey = key" @on-clear="searchKey = null" clearable>
      <span slot="prepend">搜索</span>
    </i-input>
    <div class="data-list" ref="content" style="margin-top: 10px; flex: 1; height: 0;">
      <Table ref="table" :loading="loading.load" :data="pageDataList" :columns="columns" :height="tableHeight"></Table>
    </div>
    <div style="padding: 10px;background-color: #FFF;">
      <Page @on-change="val => page.num = val" :total="searchDataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { request } from '@/api'

export default {
  data () {
    return {
      dataList: [],
      loading: {
        load: false
      },
      tableHeight: null,
      configList: [],
      page: {
        size: 30,
        num: 1
      },
      searchKey: null,
    }
  },
  computed: {
    searchDataList () {
      const searchKey = this.searchKey
      if (searchKey) {
        return this.dataList.filter(v => String(v.code).indexOf(searchKey) > -1 || String(v.wageWorkerName).indexOf(searchKey) > -1)
      }
      return this.dataList
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.searchDataList.slice(start, end)
    },
    titleMenus () {
      return [
        { key: 'back', label: '返回' },
        { key: 'reload', label: '刷新', fun: 'getDetail' },
        { key: 'check', label: '重新发布', fun: 'switchStatus' },
        { key: 'export', label: '导出结果', fun: 'exportDetail' }
      ].filter(v => {
        return !v.fun || this.$authFunsProxyNoRoute['performance.wages.manage.index-' + v.fun]
      })
    },
    columns () {
      const configList = this.configList
      return [
        { key: 'code', title: '代码', width: 100, fixed: 'left' },
        { key: 'wageWorkerName', title: '姓名', width: 100, fixed: 'left' },
        ...configList.map(v => {
          return { key: v.valueKey, title: v.valueLabel, minWidth: 100 }
        })
      ]
    }
  },
  methods: {
    async clickTitleMenu (key) {
      if (key === 'back') {
        this.$router.back()
      } else if (key === 'check' && await this.$confirm({ title: '确定重新发布吗？' })) {
        await request('/performance/wage_month/status/' + this.$route.params.monthData.id, 'post', {
          status: 'check'
        })
        this.$router.replace({
          name: 'performance.wages.manage.detail',
          query: { id: this.$route.params.monthData.id }
        })
      } else if (key === 'export') {
        this.exportData()
      } else if (key === 'reload') {
        this.loadData()
      }
    },
    exportData () {
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet([
        this.columns.map(v => v.title),
        ...this.dataList.map(item => {
          return this.columns.map(v => item[v.key])
        })
      ]), '工资明细')
      XLSX.writeFile(workbook, '工资明细.xlsx')
    },
    async loadData () {
      if (!this.$authFunsProxyNoRoute['performance.wages.manage.index-getDetail']) {
        return
      }
      this.loading.load = true
      try {
        const configList = await request('/performance/wage_month/worker_config/' + this.$route.params.monthData.id, 'get')
        this.configList = configList.sort((a, b) => {
          if (a.sortNum === null) {
            return 1
          } else {
            return a.sortNum - b.sortNum
          }
        })
        this.dataList = await request('/performance/wage_month_worker', 'get', {
          wageMonthId: this.$route.params.monthData.id
        })
      } catch (e) {
        this.$Modal.error({
          title: '加载失败', content: e.message, onOk: this.$router.back()
        })
      }
      this.loading.load = false
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
    },
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
    this.loadData()
  },
  destroyed () {
    this.dataList = []
    window.removeEventListener('resize', this.resize)
  }
}
</script>
